<template>
  <div>
    <div style="display: flex">
      <el-container>
        <el-main>
          <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.dc.largeBlock.title')">
          </head-layout>


          <div
            style="margin-top: 10px; display: flex;justify-content: space-between;padding: 0 10px 0 10px;position: relative;">
            <div style=" cursor: pointer;font-size: 40px; position: absolute; top: 32%;color: white;" @click="prevImg">
              <i class="el-icon-arrow-left"></i>
            </div>

            <div v-for="(item, index) in largeBox" style="width: 46%;">
              <div>

                <img :src=item.pictureUrl style="width: 100%;height:100%;object-fit: cover;">
              </div>
              <div>

                <el-table :data="item.largeBlockDataInfoList" border style="margin-top: 10px" height="150">
                  <el-table-column prop="recordTime" :label="$t('cip.dc.largeBlock.recordTime')">
                  </el-table-column>

                  <el-table-column prop="stoneSize" label="石块尺寸"> </el-table-column>
                  <el-table-column prop="stoneArea" label="石块面积">
                  </el-table-column>


                </el-table>
              </div>
            </div>
            <div style=" cursor: pointer;font-size: 40px; position: absolute; top: 32%;right: 10px;color: white;"
              @click="nextImg">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>


          <div>


            <el-form label-position="top" label-width="100px" :model="searchParms"
              style="display: flex;padding-left: 20px;margin-top: 20px;align-items: center" :rules="rules">
              <el-form-item label="时间范围" prop="allTime">
                <el-date-picker v-model="searchParms.allTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="尺寸阀值" prop="sizeThreshold">
                <el-input v-model="searchParms.sizeThreshold" placeholder="尺寸阀值"
                  style="width: 200px;margin-left: 5px;"></el-input>
              </el-form-item>
              <el-form-item label="面积阀值" prop="areaThreshold">
                <el-input v-model="searchParms.areaThreshold" placeholder="面积阀值"
                  style="width: 200px; margin-left: 5px;"></el-input>
              </el-form-item>
              <el-button type="primary" @click="MgetlistLargeBlockThreshold()"
                style="margin-left: 10px;margin-top: 12px;">搜索</el-button>
            </el-form>
          </div>

          <div style="padding-left: 20px;">
            <el-table :data="largetable" border style="margin-top: 20px" max-height="350" @row-click="handleRowClick"
              ref="myTable" :highlight-current-row="true">
              <el-table-column prop="recordTime" label="时间">
              </el-table-column>

              <el-table-column prop="sizeCount" label="超过尺寸阈值次数"> </el-table-column>
              <el-table-column prop="areaCount" label="超过面积告警次数">
              </el-table-column>


            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="page.pageNum" :page-sizes="[20, 50, 100, 200]" :page-size="20"
              layout="total, sizes, prev, pager, next, jumper" :total="page.total" style="float: right;">
            </el-pagination>
          </div>









          <div style="margin-top: 30px">

            <div style="width: 100%">

              <div ref="BottomEchart" class="echart" :style="myChartStyle" id="BottomEchart"></div>
            </div>
          </div>
        </el-main>
      </el-container>


    </div>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index";
import {

  updateNhfxSummary,
  getlargeblockshow,
  getlistLargeBlockThreshold,
} from "@/api/dataAcquisition/index";
import * as echarts from "echarts";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
  },
  watch: {},
  data() {
    return {
      timer: null,
      nowTime: "",
      newList2: [],
      rules: {

        allTime: [
          { required: true, message: '请选择时间范围', trigger: 'blur' }
        ],
        sizeThreshold: [
          { required: true, message: '请填写尺寸阈值', trigger: 'blur' }
        ],
        areaThreshold: [
          { required: true, message: '请填写面积阈值', trigger: 'blur' }
        ],
      },
      isfirst: true,
      searchParms: {
        allTime: [],
        sizeThreshold: "200",
        areaThreshold: "10000",
      },
      page: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },


      largeBox: [],
      largetable: [],
      largeEcharts: [],


      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },



      myChartStyle: {
        float: "left",
        width: "100%",
        height: "500px",
        margin: "5px",
      }, // 图表样式

      timeData: [],




      xAxis: {
        axisLabel: {
          interval: 0,
          rotate: 30,
        },
        data: [],
      },
      option: {
        tooltip: {},
        title: {
          show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
          text: "大块识别趋势展示"
        },
        xAxis: {},
        yAxis: [
          {
            type: 'value',
            name: '尺寸',
            position: 'left',

          },
          {
            type: 'value',
            name: '面积',
            position: 'right',

          }
        ],

        legend: {

          data: [
            {
              name: "尺寸",
            },
            {
              name: "面积",
            },

          ],

          // top: "0%", // 设置图例位置在顶部
          height: 30,
          top: "0%",
          bottom: "3%",
        },


      },




    };
  },

  methods: {
    prevImg() {
      this.nowTime = this.dateCalc(this.nowTime, "m", -1)
      this.Mgetlargeblockshow(this.nowTime)
    },
    nextImg() {
      this.nowTime = this.dateCalc(this.nowTime, "m", 1)
      let date1 = new Date(this.nowTime);
      let date2 = new Date(this.getCurrentDateTime());
      if (date1 >= date2) {
        this.nowTime = this.dateCalc(this.nowTime, "m", -1)
        this.$message({
          message: '已是最新数据！',
          type: 'warning'
        });
      } else {
        this.Mgetlargeblockshow(this.nowTime)
      }
    },
    dateCalc(date, type, number) {
      var timestamp1 = Date.parse(new Date(date));
      if (isNaN(timestamp1)) {//兼容IE,safari...
        date = date.replace(/-/ig, "/");
        timestamp1 = Date.parse(new Date(date));
      }
      var timestamp2;
      switch (type) {
        case "h":
          timestamp2 = timestamp1 + number * 60000 * 60;
          break;
        case "m":
          timestamp2 = timestamp1 + number * 60000;
          break;
        case "s":
          timestamp2 = timestamp1 + number * 1000;
          break;
        case "mi":
          timestamp2 = timestamp1 + number;
          break;
      }
      var date = new Date(timestamp2);
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },

    handleRowClick(row, column, event) {
      // 高亮当前行
      this.$refs.myTable.setCurrentRow(row);
      const date = new Date(row.recordTime);
      const formattedDateTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      this.nowTime = formattedDateTime
      this.Mgetlargeblockshow(formattedDateTime)
    },

    initEcharts(id, option) {
      const myChart = echarts.init(document.getElementById(id));
      myChart.setOption(option);
      // 随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },

    handleSizeChange(val) {
      this.page.pageNum = 1
      this.page.pageSize = val
      this.MgetlistLargeBlockThreshold()
    },
    handleCurrentChange(val) {
      this.page.pageNum = val
      this.MgetlistLargeBlockThreshold()
    },




    //创建折现图
    createES() {
      let sizeList = [];
      let AreaList = []

      let newList = []
      if (this.largeEcharts.length > 30) {
        newList = this.largeEcharts.slice(-30)
        this.newList2 = newList
      } else {
        newList = this.largeEcharts
        this.newList2 = this.largeEcharts
      }
      newList.forEach(element => {
        sizeList.push(element.stoneSize)
      });
      newList.forEach(element => {
        AreaList.push(element.stoneArea)
      });
      let xAxis = {
        axisLabel: {
          interval: 0,
          rotate: 30,
        },
        data: [],
      };
      this.option.series = []

      newList.forEach(element => {
        this.option.series.push({
          type: "line", // 形状为折现
          data: sizeList,
          name: "尺寸", // legend属性
          yAxisIndex: 0,
          symbolSize: 8,
          label: {
            // 柱状图上方文本标签，默认展示数值信息
            // show: true,
            // position: "top",
          },
        });
        this.option.series.push({
          type: "line", // 形状为折现
          data: AreaList,
          yAxisIndex: 1,
          name: "面积", // legend属性
          symbolSize: 8,
          label: {
            // 柱状图上方文本标签，默认展示数值信息
            // show: true,
            // position: "top",
          },
        });


        xAxis.data.push(element.recordTime.slice(10));

      });
      this.option.xAxis = xAxis
      let myChart = echarts.init(document.getElementById("BottomEchart"));
      myChart.dispose();
      // this.initEcharts("BottomEchart", this.option);
      let that = this
      this.$nextTick(() => {
        console.log(this.option,"rrrrrr");
        this.initEcharts("BottomEchart", this.option);

        let myChart = echarts.init(document.getElementById("BottomEchart"));
        myChart.on("click", function (params) {
          that.newList2.forEach(element => {
            if (element.recordTime.slice(10) == params.name) {
              const date = new Date(element.recordTime);
              const formattedDateTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
              that.nowTime = formattedDateTime
              that.Mgetlargeblockshow(formattedDateTime)
              that.Mgetlargeblockshow(that.nowTime)
            }
          });
        });
      });
    },

    // 默认时间
    timeDefault() {
      var date = new Date();
      var s1 =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      return s1;
    },


    getDivWidth() {
      const myDiv = this.$refs.myDiv
      const width = myDiv.clientWidth
      console.log('宽度为', width)
    },
    //获取大块识别图片
    Mgetlargeblockshow(time) {

      getlargeblockshow({ recordTime: time }).then(res => {
        this.largeBox = res.data.data
      })
    },
    //阀值、最大值图
    MgetlistLargeBlockThreshold() {
      let parms = {
        startTime: this.searchParms.allTime[0],
        endTime: this.searchParms.allTime[1],
        sizeThreshold: this.searchParms.sizeThreshold,
        areaThreshold: this.searchParms.areaThreshold,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      }
      if (!this.searchParms.allTime || !this.searchParms.sizeThreshold || !this.searchParms.areaThreshold) {
        this.$message({
          message: '时间范围，尺寸阈值，面积阈值为必填！',
          type: 'warning'
        });
        return
      }


      getlistLargeBlockThreshold(parms).then(res => {
        this.largetable = res.data.data.largeBlockThresholds
        this.largeEcharts = res.data.data.maxLargeBlock

        this.page.total = res.data.data.totalCount
        if (res.data.data.maxLargeBlock) {
          this.createES()
        }




      })
    },

    //获取默认时间范围
    getHalfHourAgoDateTime() {

      const currentDate = new Date();
      const currentTime = currentDate.getTime();
      const halfHourAgo = currentTime - (30 * 60 * 1000); // 减去30分钟的时间戳（单位为毫秒）
      const dateObj = new Date(halfHourAgo);

      const year = dateObj.getFullYear();
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const day = ("0" + dateObj.getDate()).slice(-2);
      const hours = ("0" + dateObj.getHours()).slice(-2);
      const minutes = ("0" + dateObj.getMinutes()).slice(-2);
      const seconds = ("0" + dateObj.getSeconds()).slice(-2);

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;



    },
    getCurrentDateTime() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      const day = ("0" + currentDate.getDate()).slice(-2);
      const hours = ("0" + currentDate.getHours()).slice(-2);
      const minutes = ("0" + currentDate.getMinutes()).slice(-2) - 1;
      const seconds = ("0" + currentDate.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    updateData(){
    this.searchParms.allTime = [this.getHalfHourAgoDateTime(), this.getCurrentDateTime()]
    this.MgetlistLargeBlockThreshold()
    this.nowTime = this.getCurrentDateTime()
    this.Mgetlargeblockshow(this.nowTime)
    },
    startTimer(){
      this.timer = setInterval(() => {
      this.updateData()
    }, 60000); // 60000毫秒等于60秒，即一分钟
    },

    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },



  },

  mounted() {

    this.searchParms.allTime = [this.getHalfHourAgoDateTime(), this.getCurrentDateTime()]
    this.MgetlistLargeBlockThreshold()
    this.nowTime = this.getCurrentDateTime()
    this.Mgetlargeblockshow(this.nowTime)
    this.startTimer();
  },
  beforeDestroy() {
    // this.delChart()

    this.clearTimer();
  },
};
</script>

<style scoped lang="scss">
.starBoxclass {
  width: 100%;
  display: flex;
  overflow: scroll;
}

.chooesd {
  border: 1px red solid !important;
}
</style>
